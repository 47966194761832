/* eslint-disable no-await-in-loop */
import { message } from 'antd';
import moment from 'moment';

import Utils from '../../../Assets/Scripts/Utils';
import ScheduleUtils from '../../../Components/Schedule/ScheduleUtils';
import { api } from '../../../Services/axiosService';

export const fetchProducts = async () => {
  try {
    const response = await api.get(`/Product`);
    return [
      ...response.data.map((product) => ({
        ...product,
        label: product.name,
        value: product.id,
      })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Produtos!');
    return [];
  }
};

export const generateCertificate = async (selectedRowKeys, taskList, subTableSelectedRowKeys) => {
  // Map selected tasks to the CertificateTableRowModel structure

  const certificateTableModel = selectedRowKeys
    .map((taskId) => {
      const task = taskList.find((t) => t.id === taskId);
      if (!task || !task.queryStudentList) return null;

      // Filter and collect student IDs for the current task
      const studentIds = task.queryStudentList
        .filter((student) => subTableSelectedRowKeys.includes(student.id))
        .map((student) => student.id);

      return {
        TaskId: taskId,
        StudentIdList: studentIds,
      };
    })
    .filter(Boolean); // Filter out any null entries

  if (certificateTableModel.length === 0) {
    message.warn('Oops. Nenhum registro válido para gerar certificado!');
    return;
  }

  const certificateEndpointModel = { CertificateTableRowList: certificateTableModel };

  try {
    const response = await fetch(`${process.env.REACT_APP_HOST}/Task/GenerateCertificate`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/zip', // Accept header should expect a ZIP file format
      },
      body: JSON.stringify(certificateEndpointModel),
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error('Failed to generate certificates');
    }

    const blob = await response.blob();
    const fileName = `Certificates_${moment(new Date()).format('YYYY-MM-DD')}.zip`; // Correct filename and extension
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    message.success('Download do certificado realizado com sucesso!');
  } catch (error) {
    console.error('Error generating certificates:', error);
    message.error('Oops. Algo deu errado ao tentar baixar o certificado!');
  }
};

const mountProductName = (product) => {
  const customProductList = ScheduleUtils.getCustomProductList();
  const isCustomProduct = customProductList.includes(product?.id);

  if (isCustomProduct) {
    return product.complement ? product.complement : product.name;
  }

  return product.name;
};

export const fetchTasks = async (filters = null) => {
  try {
    const url = '/Task/TaskList';
    const response = await api.post(url, filters);
    const tasks = response.data;

    const mappedTasks = tasks.map((task, index) => ({
      ...task,
      text: mountProductName(task.product),
      index,
      oldMainInstructorId: task?.mainInstructor?.userId,
      oldAssistantInstructorId: task?.assistantInstructor?.userId,
      notHaveCertificate: !!task.notHaveCertificate,
    }));

    return mappedTasks;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Treinamentos!');
    return [];
  }
};
