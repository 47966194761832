/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import TableAntd from '../../../Components/TableAntd/TableAntd';

export default function ProductList() {
  const columns = (getColumnSearchProps) => {
    const columns = [
      {
        dataIndex: 'status',
        title: 'Status',
        ...getColumnSearchProps('status', 'Status'),
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortDirections: ['descend', 'ascend'],
        width: '10%',
      },
      {
        dataIndex: 'name',
        title: 'Nome',
        ...getColumnSearchProps('name', 'Nome'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: 'workload',
        title: 'Carga Horária',
        width: '10%',
      },
    ];
    return columns;
  };

  return (
    <TableAntd
      columnsTable={(getColumnSearchProps) => columns(getColumnSearchProps)}
      routePermissions="Product"
      requestName="Product"
      route="/Produtos"
      cdPage="id"
      notDelete={true}
      notInactivate={true}
    />
  );
}
