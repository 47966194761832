/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select } from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Utils from '../../../Assets/Scripts/Utils';
import TaskCommentFunctions from '../../../Components/Schedule/TaskCommentFunctions';
import { api } from '../../../Services/axiosService';

import '../../TaskList/Components/ModalStyle.scss';

export default function CertificateSettingsModal({
  setIsOpen,
  certificateModelOptions,
  productOptions,
  taskList,
  selectedRowKeys,
  isSchedulingScreen,
  schedulingUpdate,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const selectedTaskList = taskList.filter(({ id }) => selectedRowKeys.includes(id));
  const [firstTask] = selectedTaskList;

  const isSameProduct = selectedTaskList.every(
    (task) => task.product.id === selectedTaskList[0].product.id
  );

  if (isSameProduct) {
    const product = productOptions.find(
      ({ idBitrixProduct }) => idBitrixProduct === firstTask.product.id
    );

    form.setFieldsValue({
      certificateWorkload: product.workload,
      certificateSayings: product.sayings,
      certificateProgramContentList: product.programContentList,
    });
  }

  const submit = async ({
    certificateModel,
    certificateSayings,
    certificateProgramContentList,
    certificateWorkload,
  }) => {
    try {
      if (selectedRowKeys.length > 0) {
        setIsLoading(true);

        const model = certificateModelOptions.find(({ url }) => url === certificateModel);

        for (let index = 0; index < selectedTaskList.length; index += 1) {
          const newTask = selectedTaskList[index];
          const oldTask = JSON.parse(JSON.stringify(selectedTaskList[index]));

          newTask.certificateModel = model;

          if (certificateSayings && certificateSayings !== '') {
            newTask.certificateSayings = certificateSayings;
          }
          if (certificateProgramContentList && certificateProgramContentList?.length > 0) {
            newTask.certificateProgramContentList = certificateProgramContentList;
          }
          if (certificateWorkload) {
            newTask.certificateWorkload = certificateWorkload;
          }

          // Atualiza Treinamento
          if (isSchedulingScreen) {
            await schedulingUpdate({ newData: newTask, oldData: oldTask });
          } else {
            await api.put('/Task/V2/UpdateTaskScheduling', newTask);
          }

          // Gera Atividade de Upload de Arquivo
          await TaskCommentFunctions.addTaskCommentUploadFile(
            newTask,
            'CertificateModelUploaded',
            'Certificates'
          );
        }
      }
      message.success('Tarefas atualizadas com sucesso!');
      window.location.reload();
    } catch (error) {
      Utils.error('Erro ao atualizar tarefas:', error);
      message.error('Erro ao atualizar tarefas.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      name="certificateSettings"
      width="80vw"
      title="Configuração de Certificado no Treinamento"
      open={true}
      onOk={() => form.submit()}
      onCancel={() => setIsOpen(false)}
      okText="Salvar"
      cancelText="Cancelar"
      className="certificate-settings-modal"
      okButtonProps={{
        style: { backgroundColor: '#5cb85c', color: 'white', border: 'none' },
      }}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      closable={!isLoading}
      maskClosable={!isLoading}
    >
      {isSameProduct && (
        <p>
          Os Treinamentos selecionados <strong>possuem</strong> o mesmo serviço,
          <strong> {firstTask.product.name}</strong>, por isso os campos já vieram preenchidos. Por
          favor, selecione um modelo e revise as informações antes de salvar.
        </p>
      )}

      {!isSameProduct && (
        <p>
          Os Treinamentos selecionados <strong>não possuem</strong> o mesmo serviço, por isso os
          campos não vieram preenchidos. Por favor, selecione um modelo, preencha os campos se
          necessário e revise as informações antes de salvar.
        </p>
      )}

      <Form
        form={form}
        layout="vertical"
        onFinish={submit}
        style={{ maxWidth: '100%', margin: '0 auto' }}
      >
        <Form.Item
          label="Modelo Certificado"
          name="certificateModel"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório!',
            },
          ]}
          fieldId="name"
        >
          <Select
            placeholder="Selecione"
            optionFilterProp="label"
            dropdownStyle={{ borderRadius: 16 }}
            options={certificateModelOptions.map((option) => ({
              label: option.label,
              value: option.url,
            }))}
          />
        </Form.Item>

        {isSameProduct && (
          <Form.Item label="Produto" name="certificateProduct">
            <p>{firstTask.product.name}</p>
          </Form.Item>
        )}

        <Form.Item label="Carga Horária" name="certificateWorkload">
          <InputNumber min={0} max={100} />
        </Form.Item>

        <Form.Item label="Dizeres" name="certificateSayings">
          <Input.TextArea
            rows={4}
            placeholder="Adicione os dizeres"
            style={{ borderRadius: '10px' }}
          />
        </Form.Item>

        <Form.List name="certificateProgramContentList">
          {(fields, { add, remove }) => (
            <>
              <Button
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  width: 400,
                }}
                type="dashed"
                onClick={() => {
                  add();
                }}
                block
                icon={<PlusOutlined />}
              >
                Adicionar Conteúdo Programático
              </Button>

              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={[24]} key={key}>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'module']}
                      rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    >
                      <Input placeholder="Módulo" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'subject']}
                      rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    >
                      <Input placeholder="Assunto" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item {...restField} name={[name, 'theoreticalGoals']}>
                      <Input placeholder="Objetivos Teóricos" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item {...restField} name={[name, 'practicalGoals']}>
                      <Input placeholder="Objetivos Práticos" />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <DeleteOutlined
                      style={{ color: 'red', float: 'right' }}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}
