/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Popconfirm,
  Radio,
  Row,
  Select,
  Tooltip,
  Upload,
} from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlined, EditOutlined, QuestionOutlined, UploadOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';
import { deleteFileDocuments, uploadFiles } from '../../Services/firebaseService';

import { getDocumentTypes, getSettings } from './API/SettingsAPI';
import CertificateFieldsModal from './Components/CertificateFieldsModal';

import './Settings.scss';

function SettingsPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formHoliday] = Form.useForm();
  const [formUploadFile] = Form.useForm();

  const [screenModified, setScreenModified] = useState(false);
  const [fieldsPermissions, setFieldsPermissions] = useState();
  const [loading, setLoading] = useState(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [newHolidayList, setNewHolidayList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [submitFileList, setSubmitFileList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editValue, setEditValue] = useState('');
  const [isCertificateModalVisible, setIsCertificateModalVisible] = useState(false);

  const handleCertificateModelUploadChange = (info) => {
    const newFileList = info.fileList.map((fileProps) => ({ ...fileProps, status: 'done' }));
    setFileList(newFileList);
  };

  const handleCertificateModelStartEdit = (index, currentName) => {
    setEditingIndex(index);
    setEditValue(currentName);
  };

  const handleCertificateModelCancelEdit = () => {
    setEditingIndex(-1);
    setEditValue('');
  };

  const handleCertificateModelEdit = (index) => {
    const newList = [...submitFileList];
    newList[index].label = editValue;

    setSubmitFileList(newList);
    handleCertificateModelCancelEdit();
  };

  const handleCertificateModel = async () => {
    try {
      const values = await formUploadFile.validateFields();

      const mappedFiles = [];
      for (const file of fileList) {
        const fileExtension = file.name.split('.').pop();
        const fileName = `${values.certificateModelFileName}_${Date.now()}.${fileExtension}`;

        mappedFiles.push({
          ...file,
          id: Utils.generateUUID(),
          label: values.certificateModelFileName,
          name: fileName,
          url: null,
          modelType: values.certificateModelType,
        });
      }

      // Atualiza o estado da lista de arquivos carregados
      setSubmitFileList((prevList) => [...prevList, ...mappedFiles]);

      // Limpa os campos do formulário e reseta o estado do fileList
      formUploadFile.resetFields();
      setFileList([]);
    } catch (error) {
      message.error(`Erro ao processar o formulário: ${error.message}`);
    }
  };

  const submitHoliday = (values) => {
    const { holidayDate, holidayName, holidayLevel, holidayType } = values;

    newHolidayList.push({
      date: holidayDate,
      name: holidayName,
      level: holidayLevel,
      type: holidayType,
      law: null,
    });
    setNewHolidayList([...newHolidayList]);

    formHoliday.resetFields();
  };

  const submit = async (values, newList) => {
    setLoading(true);
    const data = { ...values };

    if (data.requiredDocumentsCT?.length > 0) {
      data.requiredDocumentsCT = data.requiredDocumentsCT.join(',');
    } else {
      data.requiredDocumentsCT = null;
    }
    if (data.requiredDocumentsInstructor?.length > 0) {
      data.requiredDocumentsInstructor = data.requiredDocumentsInstructor.join(',');
    } else {
      data.requiredDocumentsInstructor = null;
    }
    if (data.requiredDocumentsDriver?.length > 0) {
      data.requiredDocumentsDriver = data.requiredDocumentsDriver.join(',');
    } else {
      data.requiredDocumentsDriver = null;
    }
    if (data.requiredDocumentsTransport?.length > 0) {
      data.requiredDocumentsTransport = data.requiredDocumentsTransport.join(',');
    } else {
      data.requiredDocumentsTransport = null;
    }
    if (data.requiredDocumentsVehicle?.length > 0) {
      data.requiredDocumentsVehicle = data.requiredDocumentsVehicle.join(',');
    } else {
      data.requiredDocumentsVehicle = null;
    }

    const certificateList = newList || submitFileList;
    if (certificateList.length > 0) {
      for (let index = 0; index < certificateList.length; index += 1) {
        const file = certificateList[index];

        if (!file.url) {
          const downloadUrl = await uploadFiles(file.originFileObj, 'settings', 1, file.name);
          file.url = downloadUrl;
        }
      }

      data.certificateModelList = certificateList;
    } else {
      data.certificateModelList = null;
    }

    data.holidayList = newHolidayList;

    try {
      await api.put('/Settings', data);
      message.success('Dados atualizados!');
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao atualizar os dados!');
    } finally {
      setLoading(false);
    }
  };

  const handleCertificateModelDelete = async (file, index) => {
    try {
      const { label, name, url } = file;

      // Atualize o estado para remover o arquivo da lista
      const clearList = () => {
        const newList = [...submitFileList];
        newList.splice(index, 1);
        setSubmitFileList(newList);
        return newList;
      };

      if (url) {
        // Chame o método para remover o arquivo do Firebase
        await deleteFileDocuments(name, 'settings', 1);
        const newList = clearList();
        await submit(form.getFieldsValue(), newList);
      }

      clearList();
      message.success(`Modelo "${label}" removido com sucesso.`);
    } catch (error) {
      message.error('Oops. Ocorreu um erro ao tentar remover o arquivo!');
    }
  };

  const handleImport = async (file) => {
    if (file.status !== 'removed') {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    }
  };

  const fetchData = async () => {
    const [documentTypes, settings] = await Promise.all([getDocumentTypes(), getSettings()]);

    setSubmitFileList(settings.certificateModelList ?? []);
    form.setFieldsValue(settings);
    setNewHolidayList(settings?.holidayList ?? []);

    setDocumentTypeOptions(documentTypes);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (fileList.length > 0) {
      const latestFile = fileList[fileList.length - 1];
      const fileNameWithoutExtension = latestFile.name.replace(/\.[^/.]+$/, '');
      formUploadFile.setFieldsValue({ certificateModelFileName: fileNameWithoutExtension });
    }
  }, [fileList, formUploadFile]);

  useLayoutEffect(() => {
    const permissions = {};
    JSON.parse(localStorage.getItem('conecta__permissions'))?.resources.forEach(
      ({ name, fields }) => {
        if (name === 'Configuration') {
          fields.forEach(({ name, access, isRequired }) => {
            permissions[name] = { access, isRequired };
          });
        }
      }
    );
    setFieldsPermissions(permissions);
  }, []);

  return (
    <>
      <Form
        form={form}
        disabled={loading}
        name="settingsForm"
        layout="vertical"
        onFinish={submit}
        autoComplete="off"
        onChange={() => setScreenModified(true)}
      >
        <div className="form-toolbar">
          {screenModified && (
            <Popconfirm
              title="Deseja mesmo voltar?"
              onConfirm={() => navigate(-1)}
              okText="Sim"
              cancelText="Não"
            >
              <Button>Voltar</Button>
            </Popconfirm>
          )}
          {!screenModified && <Button onClick={() => navigate(-1)}>Voltar</Button>}
          <Button loading={loading} block type="primary" htmlType="submit">
            Salvar
          </Button>
        </div>
        <Row gutter={[24]}>
          {(fieldsPermissions?.Email.access !== 0 ||
            fieldsPermissions?.EmailPassword.access !== 0 ||
            fieldsPermissions?.EmailHost.access !== 0 ||
            fieldsPermissions?.EmailPort.access !== 0) && (
            <Col span={24}>
              <Divider className="configuration-divider" orientation="left">
                E-mail Remetente
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.EmailHost.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Host"
                name="emailHost"
                rules={[
                  {
                    required: fieldsPermissions?.EmailHost.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input disabled={fieldsPermissions?.EmailHost.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.EmailPort.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Porta"
                name="emailPort"
                rules={[
                  {
                    required: fieldsPermissions?.EmailPort.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  max={9999}
                  placeholder="0"
                  disabled={fieldsPermissions?.EmailPort.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.Email.access !== 0 && (
            <Col span={6}>
              <Form.Item label="id" name="id" className="input-hidden" initialValue={1}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: fieldsPermissions?.Email.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input
                  placeholder="exemplo@email.com"
                  disabled={fieldsPermissions?.Email.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.EmailPassword.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Senha"
                name="emailPassword"
                rules={[
                  {
                    required: fieldsPermissions?.EmailPassword.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input.Password disabled={fieldsPermissions?.EmailPassword.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {(fieldsPermissions?.FinishMargin.access !== 0 ||
            fieldsPermissions?.PostConclusionTime.access !== 0 ||
            fieldsPermissions?.TaskAcceptanceDeadline.access !== 0 ||
            fieldsPermissions?.HoursToShowInstructorData.access !== 0) && (
            <Col span={24}>
              <Divider className="configuration-divider" orientation="left">
                Agenda
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.FinishMargin.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Tempo de Pré-Conclusão (Min)"
                name="conclusionMargin"
                rules={[
                  {
                    required: fieldsPermissions?.FinishMargin.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  max={480}
                  placeholder="0"
                  disabled={fieldsPermissions?.FinishMargin.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.PostConclusionTime.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Tempo de Pós-Conclusão (Horas)"
                name="postConclusionTime"
                rules={[
                  {
                    required: fieldsPermissions?.PostConclusionTime.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  max={168}
                  placeholder="0"
                  disabled={fieldsPermissions?.PostConclusionTime.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.TaskAcceptanceDeadline.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Prazo de Aceite Treinamento (Horas)"
                name="taskAcceptanceDeadline"
                rules={[
                  {
                    required: fieldsPermissions?.TaskAcceptanceDeadline.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  max={168}
                  placeholder="0"
                  disabled={fieldsPermissions?.TaskAcceptanceDeadline.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.HoursToShowInstructorData.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Visualização de campos Instrutor (Horas)"
                name="hoursToShowInstructorData"
                rules={[
                  {
                    required: fieldsPermissions?.HoursToShowInstructorData.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  max={168}
                  placeholder="0"
                  disabled={fieldsPermissions?.HoursToShowInstructorData.access !== 2}
                />
              </Form.Item>
            </Col>
          )}
          {(fieldsPermissions?.JobMinuteFTAE.access !== 0 ||
            fieldsPermissions?.JobHourFTAE.access !== 0 ||
            fieldsPermissions?.JobDayFTAE.access !== 0) && (
            <Col
              span={
                fieldsPermissions?.JobMinuteCTED.access !== 0 &&
                fieldsPermissions?.JobHourCTED.access !== 0 &&
                fieldsPermissions?.JobDayCTED.access !== 0
                  ? 12
                  : 24
              }
            >
              <Divider className="configuration-divider" orientation="left">
                Cron - Concluir Treinamento com Atraso
              </Divider>
            </Col>
          )}

          {(fieldsPermissions?.JobMinuteCTED.access !== 0 ||
            fieldsPermissions?.JobHourCTED.access !== 0 ||
            fieldsPermissions?.JobDayCTED.access !== 0) && (
            <Col
              span={
                fieldsPermissions?.JobMinuteFTAE.access !== 0 &&
                fieldsPermissions?.JobHourFTAE.access !== 0 &&
                fieldsPermissions?.JobDayFTAE.access !== 0
                  ? 12
                  : 24
              }
            >
              <Divider className="configuration-divider" orientation="left">
                Cron - Verificar Data de Expiração
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.JobMinuteCTED.access !== 0 && (
            <Col span={4}>
              <Form.Item
                label="Minuto"
                name="jobMinuteCTED"
                rules={[
                  {
                    required: fieldsPermissions?.JobMinuteCTED.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input maxLength={5} disabled={fieldsPermissions?.JobMinuteCTED.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.JobHourCTED.access !== 0 && (
            <Col span={4}>
              <Form.Item
                label="Hora"
                name="jobHourCTED"
                rules={[
                  {
                    required: fieldsPermissions?.JobHourCTED.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input maxLength={5} disabled={fieldsPermissions?.JobHourCTED.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.JobDayCTED.access !== 0 && (
            <Col span={4}>
              <Form.Item
                label="Dia"
                name="jobDayCTED"
                rules={[
                  {
                    required: fieldsPermissions?.JobDayCTED.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input maxLength={5} disabled={fieldsPermissions?.JobDayCTED.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.JobMinuteFTAE.access !== 0 && (
            <Col span={4}>
              <Form.Item
                label="Minuto"
                name="jobMinuteFTAE"
                rules={[
                  {
                    required: fieldsPermissions?.JobMinuteFTAE.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input maxLength={5} disabled={fieldsPermissions?.JobMinuteFTAE.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.JobHourFTAE.access !== 0 && (
            <Col span={4}>
              <Form.Item
                label="Hora"
                name="jobHourFTAE"
                rules={[
                  {
                    required: fieldsPermissions?.JobHourFTAE.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input maxLength={5} disabled={fieldsPermissions?.JobHourFTAE.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.JobDayFTAE.access !== 0 && (
            <Col span={4}>
              <Form.Item
                label="Dia"
                name="jobDayFTAE"
                rules={[
                  {
                    required: fieldsPermissions?.JobDayFTAE.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input maxLength={5} disabled={fieldsPermissions?.JobDayFTAE.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.BitrixWebhookUrl.access !== 0 && (
            <Col span={24}>
              <Divider className="configuration-divider" orientation="left">
                Bitrix
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.BitrixWebhookUrl.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Webhook Bitrix"
                name="bitrixWebhookUrl"
                rules={[
                  {
                    required: fieldsPermissions?.BitrixWebhookUrl.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input disabled={fieldsPermissions?.BitrixWebhookUrl.access !== 2} />
              </Form.Item>
            </Col>
          )}
          {(fieldsPermissions?.CnpjApiUrl.access !== 0 ||
            fieldsPermissions?.CnpjApiToken.access !== 0) && (
            <Col span={24}>
              <Divider className="configuration-divider" orientation="left">
                API CNPJ
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.CnpjApiUrl.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="URL"
                name="cnpjApiUrl"
                rules={[
                  {
                    required: fieldsPermissions?.CnpjApiUrl.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input disabled={fieldsPermissions?.CnpjApiUrl.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.CnpjApiToken.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Token"
                name="cnpjApiToken"
                rules={[
                  {
                    required: fieldsPermissions?.CnpjApiToken.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input.Password disabled={fieldsPermissions?.CnpjApiToken.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {(fieldsPermissions?.HolidayApiUrl.access !== 0 ||
            fieldsPermissions?.HolidayApiToken.access !== 0) && (
            <Col span={24}>
              <Divider className="configuration-divider" orientation="left">
                API Feriados
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.HolidayApiUrl.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="URL"
                name="holidayApiUrl"
                rules={[
                  {
                    required: fieldsPermissions?.HolidayApiUrl.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input disabled={fieldsPermissions?.HolidayApiUrl.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.HolidayApiToken.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Token"
                name="holidayApiToken"
                rules={[
                  {
                    required: fieldsPermissions?.HolidayApiToken.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input.Password disabled={fieldsPermissions?.HolidayApiToken.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {(fieldsPermissions?.Bitrix24QueueMiddlewareEndpoint.access !== 0 ||
            fieldsPermissions?.Bitrix24QueueMiddlewareAuthToken.access !== 0) && (
            <Col span={24}>
              <Divider className="configuration-divider" orientation="left">
                Sistema de Fila
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.Bitrix24QueueMiddlewareEndpoint.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Endpoint Sistema de Fila"
                name="bitrix24QueueMiddlewareEndpoint"
                rules={[
                  {
                    required: fieldsPermissions?.Bitrix24QueueMiddlewareEndpoint.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input disabled={fieldsPermissions?.Bitrix24QueueMiddlewareEndpoint.access !== 2} />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.Bitrix24QueueMiddlewareAuthToken.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Token Sistema de Fila"
                name="bitrix24QueueMiddlewareAuthToken"
                rules={[
                  {
                    required: fieldsPermissions?.Bitrix24QueueMiddlewareAuthToken.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input.Password
                  disabled={fieldsPermissions?.Bitrix24QueueMiddlewareAuthToken.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {(fieldsPermissions?.RequiredDocumentsCT.access !== 0 ||
            fieldsPermissions?.RequiredDocumentsInstructor.access !== 0 ||
            fieldsPermissions?.RequiredDocumentsDriver.access !== 0 ||
            fieldsPermissions?.RequiredDocumentsTransport.access !== 0 ||
            fieldsPermissions?.RequiredDocumentsVehicle.access !== 0) && (
            <Col span={24}>
              <Divider className="configuration-divider" orientation="left">
                Documentos Obrigatórios
              </Divider>
            </Col>
          )}

          {fieldsPermissions?.RequiredDocumentsCT.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Documentos Obrigatórios CT"
                name="requiredDocumentsCT"
                rules={[
                  {
                    required: fieldsPermissions?.RequiredDocumentsCT.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Selecione"
                  optionFilterProp="label"
                  showSearch
                  options={documentTypeOptions}
                  dropdownStyle={{ borderRadius: 16 }}
                  maxTagCount="responsive"
                  disabled={fieldsPermissions?.RequiredDocumentsCT.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.RequiredDocumentsInstructor.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Documentos Obrigatórios Instrutor"
                name="requiredDocumentsInstructor"
                rules={[
                  {
                    required: fieldsPermissions?.RequiredDocumentsInstructor.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Selecione"
                  optionFilterProp="label"
                  showSearch
                  options={documentTypeOptions}
                  dropdownStyle={{ borderRadius: 16 }}
                  maxTagCount="responsive"
                  disabled={fieldsPermissions?.RequiredDocumentsInstructor.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.RequiredDocumentsDriver.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Documentos Obrigatórios Motorista"
                name="requiredDocumentsDriver"
                rules={[
                  {
                    required: fieldsPermissions?.RequiredDocumentsDriver.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Selecione"
                  optionFilterProp="label"
                  showSearch
                  options={documentTypeOptions}
                  dropdownStyle={{ borderRadius: 16 }}
                  maxTagCount="responsive"
                  disabled={fieldsPermissions?.RequiredDocumentsDriver.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.RequiredDocumentsTransport.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Documentos Obrigatórios Transporte"
                name="requiredDocumentsTransport"
                rules={[
                  {
                    required: fieldsPermissions?.RequiredDocumentsTransport.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Selecione"
                  optionFilterProp="label"
                  showSearch
                  options={documentTypeOptions}
                  dropdownStyle={{ borderRadius: 16 }}
                  maxTagCount="responsive"
                  disabled={fieldsPermissions?.RequiredDocumentsTransport.access !== 2}
                />
              </Form.Item>
            </Col>
          )}

          {fieldsPermissions?.RequiredDocumentsVehicle.access !== 0 && (
            <Col span={6}>
              <Form.Item
                label="Documentos Obrigatórios Veículo"
                name="requiredDocumentsVehicle"
                rules={[
                  {
                    required: fieldsPermissions?.RequiredDocumentsVehicle.isRequired,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Selecione"
                  optionFilterProp="label"
                  showSearch
                  options={documentTypeOptions}
                  dropdownStyle={{ borderRadius: 16 }}
                  maxTagCount="responsive"
                  disabled={fieldsPermissions?.RequiredDocumentsVehicle.access !== 2}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>

      <Form
        form={formHoliday}
        disabled={loading}
        name="settingsFormHoliday"
        layout="vertical"
        onFinish={submitHoliday}
        autoComplete="off"
        onChange={() => setScreenModified(true)}
        style={{ marginBottom: 32 }}
      >
        <Row gutter={[24]}>
          <Col span={24}>
            <Divider className="configuration-divider" orientation="left">
              Feriados e Pontos Facultativos
            </Divider>
          </Col>

          <Col span={12}>
            <Col span={24}>
              <Form.Item
                name="holidayDate"
                label="Data"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{
                    borderRadius: 16,
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Nome"
                name="holidayName"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Nível"
                name="holidayLevel"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Radio.Group
                  options={[
                    { label: 'Nacional', value: 'nacional' },
                    { label: 'Municipal', value: 'municipal' },
                  ]}
                  optionType="button"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Tipo"
                name="holidayType"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório!',
                  },
                ]}
              >
                <Radio.Group
                  options={[
                    { label: 'Feriado', value: 'feriado' },
                    { label: 'Facultativo', value: 'facultativo' },
                  ]}
                  optionType="button"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button type="primary" block htmlType="submit">
                Adicionar
              </Button>
            </Col>
          </Col>
          <Col span={12}>
            <List
              bordered
              style={{ borderRadius: 28 }}
              dataSource={newHolidayList}
              renderItem={(item) => {
                const date = moment(new Date(item.date)).format('DD/MM/YYYY');

                return (
                  <List.Item key={item.name} style={{ padding: 12 }}>
                    {date} | {item.name} | {item.level} | {item.type}
                  </List.Item>
                );
              }}
            />
          </Col>
        </Row>
      </Form>

      {/* Upload de arquivo de certificado */}
      <Form
        form={formUploadFile}
        disabled={loading}
        name="settingsFormHoliday"
        layout="vertical"
        onFinish={handleCertificateModel}
        autoComplete="off"
        onChange={() => setScreenModified(true)}
        style={{ marginBottom: 32 }}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Divider
              className="configuration-divider"
              orientation="left"
              style={{ marginBottom: 0, marginLeft: 0 }}
            >
              Upload de Modelos de Certificado
              <Tooltip title="Mapeamento de Campos">
                <Button
                  type="primary"
                  icon={<QuestionOutlined />}
                  onClick={() => setIsCertificateModalVisible(true)}
                  style={{ marginLeft: 8 }}
                />
              </Tooltip>
            </Divider>
          </Col>

          <Col span={12}>
            <Row gutter={[24, 0]}>
              <Col span={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                  style={{ marginTop: 12 }}
                >
                  <Upload
                    beforeUpload={() => {
                      setFileList([]);
                    }}
                    onChange={handleCertificateModelUploadChange}
                    customRequest={({ file }) => handleImport(file)}
                    disabled={loading}
                    fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Nome"
                  name="certificateModelFileName"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Tipo de Modelo"
                  name="certificateModelType"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório!',
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: 'Certificado', value: 1 },
                      { label: 'Carteirinha', value: 2 },
                    ]}
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    dropdownStyle={{ borderRadius: 16 }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Button type="primary" block htmlType="submit">
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <List
              bordered
              style={{ borderRadius: 28 }}
              dataSource={submitFileList}
              renderItem={(item, index) => (
                <List.Item
                  key={item.name}
                  style={{ padding: 12, display: 'flex', justifyContent: 'space-between' }}
                >
                  {editingIndex === index ? (
                    <Input
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onPressEnter={() => handleCertificateModelEdit(index)}
                      onBlur={() => handleCertificateModelCancelEdit()}
                    />
                  ) : (
                    <>
                      {item.label}
                      <div>
                        <EditOutlined
                          type="link"
                          onClick={() => handleCertificateModelStartEdit(index, item.label)}
                          style={{ marginRight: 8 }}
                        />
                        <DeleteOutlined
                          type="link"
                          onClick={() => handleCertificateModelDelete(item, index)}
                          style={{ color: 'red' }}
                        />
                      </div>
                    </>
                  )}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Form>
      {isCertificateModalVisible && (
        <CertificateFieldsModal setIsVisible={setIsCertificateModalVisible} />
      )}
    </>
  );
}

const Settings = memo(SettingsPage);
export default Settings;
