/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Select, Skeleton, Tooltip } from 'antd';
import { Accordion, ScrollView } from 'devextreme-react';

import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';

import Utils from '../../Assets/Scripts/Utils';
import { fetchTasks } from '../../Pages/ScheduleConecta/API/ScheduleSchedulingAPI';

import DealListFunctions from './DealListFunctions';
import Task from './Task';

import './DealList.scss';

function DealList({
  loading,
  stages: stagesParam,
  trainings: trainingsParam,
  schedulerRef,
  dealId,
  bitrixUsers,
  dealList,
  setDealList,
  trainingCenterOptions,
  fetchInstructors,
  fetchSlots,
  callSlotGeneration,
  handleFetchTasks,
  setTrainingCenter,
  taskRescheduling,
  exitReschedulingMode,
}) {
  const [form] = Form.useForm();

  const [stageOptions, setStageOptions] = useState();
  const [trainingsOptions, setTrainingsOptions] = useState();
  const [loader, setLoader] = useState(false);

  const userData = JSON.parse(localStorage.getItem('conecta__userData'));

  const filterDealsList = (global, stages, responsibles, trainings, pDealList) => {
    const searchTerm = global
      ?.normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toUpperCase(); // Limpa e Padroniza a string

    const dealsFiltered = [];

    const stagesToFilter = stages;
    const responsiblesToFilter = responsibles;
    const trainingsToFilter = trainings;

    pDealList.forEach((deal) => {
      const tasksFiltered = deal.tasks?.filter(
        // eslint-disable-next-line array-callback-return, consistent-return
        (task) => {
          let taskTextConcat = '';
          taskTextConcat += task.classId;
          taskTextConcat += ` ${task.cardTitle}`;
          taskTextConcat += ` ${task.text}`;
          taskTextConcat += ` Alunos: ${task.qtyStudentsPerClass}`;
          taskTextConcat += ` ${task.stage.name}`;
          taskTextConcat += ` ${task.startDateFormatted ?? ''}`;
          taskTextConcat += ` ${task.dealTitle}`;
          taskTextConcat += ` ${task.dealClient}`;
          taskTextConcat += ` ${task.dealClientCNPJ}`;
          taskTextConcat += ` ${task.trainingType}`;
          taskTextConcat += ` ${task.dealClientCommercialName}`;
          taskTextConcat += ` ${task.dealId}`;
          taskTextConcat = taskTextConcat
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .toUpperCase(); // Limpa e Padroniza a string

          let validTaskText = taskTextConcat.includes(searchTerm);
          let validTaskStage = stagesToFilter?.some((stage) => stage === task.stage.id);
          let validTaskResponsible = responsiblesToFilter?.some(
            (responsible) => responsible === task.responsible
          );
          let validTaskTraining = trainingsToFilter?.some((training) => training === task.group.id);

          if (!global || searchTerm === '') {
            validTaskText = true;
          }

          if (!stagesToFilter || stagesToFilter.length === 0) {
            validTaskStage = true;
          }

          if (!trainingsToFilter || trainingsToFilter.length === 0) {
            validTaskTraining = true;
          }

          if (!responsiblesToFilter || responsiblesToFilter.length === 0) {
            validTaskResponsible = true;
          }

          if (validTaskText && validTaskStage && validTaskTraining && validTaskResponsible) {
            return task;
          }
        }
      );

      if (tasksFiltered.length > 0) {
        dealsFiltered.push({ ...deal, tasks: tasksFiltered });
      }
    });

    setDealList(dealsFiltered);
    setLoader(false);
  };

  const fetchDealList = async ({ global, stages, responsibles, trainings }) => {
    try {
      setLoader(true);

      const tasks = await fetchTasks({
        stages,
        responsibles,
        trainings,
      });

      const uniqueDealsMap = new Map();

      tasks.forEach(({ dealId, quoteName }) => {
        if (!uniqueDealsMap.has(dealId)) {
          const dealTasks = tasks.filter((task) => task.dealId === dealId);
          uniqueDealsMap.set(dealId, { id: dealId, name: quoteName, tasks: dealTasks });
        }
      });

      const deals = Array.from(uniqueDealsMap.values());

      filterDealsList(global, stages, responsibles, trainings, deals);
      return deals;
    } catch (error) {
      setLoader(false);
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao buscar a Lista de Treinamentos!');
      return [];
    }
  };

  const handleChange = async (value) => {
    try {
      const trainingCenter = trainingCenterOptions.find(({ id }) => id === value);
      if (!trainingCenter) {
        message.error('Centro de treinamento não encontrado');
        return;
      }
      const ctData = JSON.parse(localStorage.getItem('conecta__scheduleData'));

      const newCtData = {
        trainingCenterId: trainingCenter.id,
        trainingCenter: trainingCenter.commercialName,
        date: ctData.date,
        viewType: ctData.viewType,
        maxCapacityPerDay: trainingCenter.maxCapacityPerDay,
      };

      localStorage.setItem('conecta__scheduleData', JSON.stringify(newCtData));

      setTrainingCenter(trainingCenter);
      await fetchInstructors();
      const slotsToFunction = await fetchSlots(trainingCenter);
      callSlotGeneration(trainingCenter, null, null, null, slotsToFunction);

      await handleFetchTasks({
        ct: trainingCenter,
        startDate: schedulerRef.current.instance.getStartViewDate(),
        endDate: schedulerRef.current.instance.getEndViewDate(),
      });
      form.submit();
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao filtrar o Centro de Treinamento!');
    }
  };

  const renderDeal = (itemData) => (
    <Task itemData={itemData} schedulerRef={schedulerRef} taskRescheduling={taskRescheduling} />
  );

  const scheduleData = JSON.parse(localStorage.getItem('conecta__scheduleData'));
  const initialCtId = scheduleData ? scheduleData.trainingCenterId : undefined;

  useEffect(() => {
    const stages = stagesParam?.map(({ id, name }) => ({ label: name, value: id }));
    stages?.unshift({ value: 'selectAll', label: 'Selecionar todos' });
    setStageOptions(stages);

    const trainings = trainingsParam?.map(({ id, name }) => ({ label: name, value: id }));
    trainings?.unshift({ value: 'selectAll', label: 'Selecionar todos' });
    setTrainingsOptions(trainings);
  }, []);

  useEffect(() => {
    form.setFieldValue('global', dealId);
    form.submit();
  }, []);

  useEffect(() => {
    DealListFunctions.calculateDealListTotal(dealList);
  }, [dealList]);

  return (
    <>
      <Form
        form={form}
        disabled={loading || loader}
        name="DealsFilter"
        onFinish={fetchDealList}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          responsibles: userData?.bitrixId ? [userData.bitrixId] : [],
          stages: stagesParam?.filter(({ id }) => ![3, 12].includes(id))?.map(({ id }) => id),
          trainingCenterId: initialCtId,
        }}
      >
        <Row gutter={[24]}>
          <Col span={5}>
            <Form.Item label="CT" name="trainingCenterId">
              <Select
                options={trainingCenterOptions.map((tc) => ({
                  label: tc.commercialName,
                  value: tc.id,
                }))}
                loading={loading}
                onChange={handleChange}
                placeholder="Selecione um CT"
                optionFilterProp="label"
                dropdownStyle={{ borderRadius: 16 }}
              />
            </Form.Item>
          </Col>
          {taskRescheduling && (
            <Col span={19}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'rgba(177, 156, 217, 0.2)',
                  padding: '5px',
                  textAlign: 'center',
                  borderRadius: '10px 16px 16px 10px',
                  marginTop: '22px',
                }}
              >
                <span style={{ flex: 2, textAlign: 'center' }}>Modo de Reagendamento</span>
                <Button danger type="text" disabled={loading} onClick={exitReschedulingMode}>
                  Cancelar
                </Button>
              </div>
            </Col>
          )}
          {!taskRescheduling && (
            <>
              <Col span={5}>
                <Form.Item label="Global" className="tooltip-globalfilter">
                  <Tooltip
                    placement="right"
                    title="É possível filtrar por: Turma, Treinamento, Qtd. Alunos, Tipo de Treinamento, Estágio, Data (DD/MM/AAAA), Número do Negócio, Nome do Negócio, Nome do Cliente e CNPJ."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Form.Item>
                <Form.Item name="global">
                  <Input placeholder="Filtro" prefix={<SearchOutlined />} allowClear />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item label="Responsável" name="responsibles">
                  <Select
                    options={bitrixUsers}
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    removeIcon={false}
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(values) =>
                      Utils.handleSelectAllChange(values, bitrixUsers, 'responsibles', form)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Estágios" name="stages">
                  <Select
                    options={stageOptions}
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    removeIcon={false}
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(values) => {
                      Utils.handleSelectAllChange(values, stageOptions, 'stages', form);
                      if (values.length >= 30) {
                        message.warning('Somente 30 estágios podem ser selecionados.');
                        form.setFieldValue('stages', []);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Treinamentos" name="trainings">
                  <Select
                    options={trainingsOptions}
                    allowClear
                    placeholder="Selecione"
                    optionFilterProp="label"
                    showSearch
                    dropdownStyle={{ borderRadius: 16 }}
                    removeIcon={false}
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(values) =>
                      Utils.handleSelectAllChange(values, trainingsOptions, 'trainings', form)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Button type="primary" htmlType="submit">
                  <SearchOutlined />
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {(loading || loader) && (
        <div className="deal-list-skeleton">
          <Skeleton.Input active block size="large" className="deal-list-skeleton-input" />
          <Skeleton.Input active block size="large" className="deal-list-skeleton-input" />
          <Skeleton.Input active block size="large" className="deal-list-skeleton-input" />
        </div>
      )}

      {!loading && !loader && (
        <ScrollView id="scrollDeals">
          <Accordion
            id="dealsList"
            disabled={loading}
            dataSource={taskRescheduling ?? dealList}
            collapsible
            multiple
            itemTitleRender={(deal) => (
              <h2>
                {deal.trainingsIndicator} {deal.id} - {deal.name}
              </h2>
            )}
            itemRender={renderDeal}
          />
        </ScrollView>
      )}
    </>
  );
}

export default React.memo(DealList);
